import React from 'react';

import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header';
import HeroGeneric from '../components/HeroGeneric/HeroGeneric';
import Social from '../components/Social/Social';
import Footer from '../components/Footer/Footer';

import SEO from '../components/seo';

const NotFoundPage = props => (
  <Layout>
    <SEO title="404: Page Not Found" />
    <Header hideLinks pathname={props.location.pathname} />
    <HeroGeneric
      style={{ paddingTop: '75px' }}
      headline="404 - Page Not Found"
    />
    <Social />
    <Footer />
  </Layout>
);

export default NotFoundPage;
