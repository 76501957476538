import React from 'react';
import PropTypes from 'prop-types';

import HeroGenericStyled from './HeroGeneric.style';
import logoD2Mobile from '../../images/logo_HeroCs_d2_mobile.png';
import logoD2Tab from '../../images/logo_HeroCs_d2_tab.png';
import logoD2Desktop from '../../images/logo_HeroCs_d2_dsktp.png';

import Heading from '../Heading/Heading';

const HeroGeneric = ({ headline, subheadline }) => (
  <HeroGenericStyled>
    <div className="HeroGeneric__inner">
      <div className="HeroGeneric__heading">
        <Heading>{headline}</Heading>
        {subheadline && <p>{subheadline}</p>}
      </div>
      <div className="HeroGeneric__logo">
        <img className="mobile" src={logoD2Mobile} alt="Destiny 2 Forsaken" />
        <img className="tab" src={logoD2Tab} alt="Destiny 2 Forsaken" />
        <img className="desktop" src={logoD2Desktop} alt="Destiny 2 Forsaken" />
      </div>
    </div>
  </HeroGenericStyled>
);

HeroGeneric.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.node.isRequired,
  subheadline: PropTypes.node,
};

HeroGeneric.defaultProps = {
  className: null,
  subheadline: null,
};

export default HeroGeneric;
