import styled from 'styled-components';
import * as defaultTheme from '../variables';

import bgMobile from '../../images/bg_HeroCs_mobile.jpg';
import bgTab from '../../images/bg_HeroCs_tab.jpg';
import bgDesktop from '../../images/bg_HeroCs_dsktp.jpg';

const HeroCsStyled = styled('div')`
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  background-image: url(${bgMobile});
  background-repeat: no-repeat;
  background-size: auto 454px;
  background-position: 45% top;
  z-index: 1;

  @media (${p => p.theme.smMin}) {
    background-image: url(${bgTab});
    background-size: auto 511px;
    background-position: 83% top;
  }

  @media (${p => p.theme.mdMin}) {
    background-size: cover;
  }

  @media (${p => p.theme.lgMin}) {
    background-image: url(${bgDesktop});
    background-position: 100% top;
  }

  .HeroGeneric__inner {
    position: relative;
    max-width: 375px;
    margin: 0 auto;
    padding: 0 20px;
    padding-top: 160px;
    padding-bottom: 30px;

    @media (${p => p.theme.smMin}) {
      max-width: ${p => p.theme.maxWidth}px;
      height: 511px;
      padding-top: 73px;
    }

    @media (${p => p.theme.mdMin}) {
      padding-top: 40px;
    }

    @media (${p => p.theme.lgMin}) {
      min-height: 700px;
      padding-top: 119px;
    }
  }

  .HeroGeneric__heading {
    @media (${p => p.theme.smMin}) {
      float: right;
      max-width: 300px;
    }

    @media (${p => p.theme.mdMin}) {
      max-width: 400px;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 570px;
    }

    p {
      margin-top: 8px;
    }
  }

  .HeroGeneric__logo {
    @media (${p => p.theme.xsMax}) {
      max-width: 335px;
      margin: 120px auto 60px;
    }

    @media (${p => p.theme.smMin}) {
      position: absolute;
      bottom: 36px;
      max-width: 359px;
    }

    @media (${p => p.theme.lgMin}) {
      bottom: 95px;
      left: 0;
      max-width: 345px;
    }

    .mobile {
      @media (${p => p.theme.smMin}) {
        display: none;
      }
    }

    .tab {
      @media (${p => p.theme.xsMax}) {
        display: none;
      }

      @media (${p => p.theme.lgMin}) {
        display: none;
      }
    }

    .desktop {
      @media (${p => p.theme.mdMax}) {
        display: none;
      }
    }
  }
`;

HeroCsStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeroCsStyled;
